import React, { FC } from 'react';
import classNames from 'classnames';
import {
  FormattedDate,
  FormattedMessage,
  IntlShape,
  useIntl,
} from 'react-intl';
import { gon } from '@viewlio/juulio-bridge/src/common/gon';

export { FormattedDate };

export const TRANSLATED_CLASS = 'intl-text';
export const MISSING_CLASS = 'translation-missing';
export const DATA_I18N_KEY = 'data-i18n-key';

/*
  In the test env, we intentionally render keys as values to increase
  stability in our tests and snapshots.
*/
export const translationIsMissing = (key, value) =>
  gon('env.RAILS_ENV') !== 'test' && key === value;

/*
 * In rails, each plural form has its own key, and we need to determine it
 * from the numeric value of <count>. A potential issue may arise since the algorithm
 * used here is not the same as in Rails.
 *
 * See:
 * http://guides.rubyonrails.org/i18n.html#pluralization.
 * https://github.com/yahoo/react-intl/wiki/API#formatplural
 * http://www.unicode.org/cldr/charts/latest/supplemental/language_plural_rules.html
 */
export const getMessageId = (id, props, intl) =>
  props.values && props.values.count !== undefined
    ? `${id}.${intl.formatPlural(props.values.count, props)}`
    : id;

type TranslatedMessageProps = {
  className?: string;
  id: string;
  values?: Record<string, unknown>;
  textComponent?: React.ElementType;
};

export const TranslatedMessage: FC<TranslatedMessageProps> = props => {
  const intl = useIntl();
  const messageId = getMessageId(props.id, props, intl);

  const renderMessageContent = (...args) => {
    const parts = [].slice.call(args);
    const message = parts[0];
    const messageIdWithPrefix = `js.${messageId}`;

    const translationMissing = translationIsMissing(message, messageId);

    let className = classNames(classNames(TRANSLATED_CLASS, props.className), {
      [MISSING_CLASS]: translationMissing,
    });

    // TODO: react-intl now defaults this to React.Fragment. We reset it
    // back to our expected default of 'span', but in the future it would
    // be nice to use React.Fragments instead.
    // const { textComponent: Text } = this.context;
    const Text = props.textComponent || 'span';

    if (gon('env.RAILS_ENV') === 'production' && translationMissing) {
      className = classNames(className, 'invisibile');
      if (typeof global.bugsnagClient !== 'undefined') {
        global.bugsnagClient.notify(
          `TranslatedMessage: Missing translation id: ${messageId}`
        );
      }
    }

    return React.createElement(
      Text,
      { [DATA_I18N_KEY]: messageIdWithPrefix, className },
      ...parts
    );
  };

  return (
    <FormattedMessage {...props} id={messageId}>
      {renderMessageContent}
    </FormattedMessage>
  );
};

TranslatedMessage.displayName = 'TranslatedMessage'; // for enzyme compatibility

// Use this when you need to get just the translated string. Must provide intl.
export const translateMessage = (
  intl: IntlShape,
  id: string,
  values?: Record<string, any>
) => intl.formatMessage({ id: getMessageId(id, { values }, intl) }, values);

// Curried version of translateMessage.
export const messageTranslator = (intl: IntlShape) => (
  id: string,
  values?: Record<string, any>
) => translateMessage(intl, id, values);

export const useTranslations = () => {
  const intl = useIntl();
  return messageTranslator(intl);
};

type TranslatedLinkMessageProps = {
  id: string;
  link: string;
  linkText: string;
  inlineData?: Record<string, unknown>;
  opts?: { templateKey: string };
};

export const TranslatedLinkMessage: FC<TranslatedLinkMessageProps> = ({
  id,
  link,
  linkText,
  inlineData = {},
  opts = {},
}) => {
  const intl = useIntl();
  const templateKey = opts.templateKey || 'anchor';
  const linkData = translateMessage(intl, link);
  const linkTextData = translateMessage(intl, linkText);
  const anchor = <a href={linkData}>{linkTextData}</a>;
  return (
    <TranslatedMessage
      id={id}
      values={{ [templateKey]: anchor, ...inlineData }}
    />
  );
};
