import React from 'react'

import { ContentWrapper } from '@juullabs/react-components'
import { TranslatedMessage } from '@viewlio/juulio-bridge/src/common/components/Intl'
import { GetGlobalPagesResponse } from '@viewlio/types/src/api/juulio'
import { FooterEntry } from '@viewlio/types/src/contentful'

import { NicotineWarning } from 'components/common/NicotineWarning'
import { LayoutContentfulEntries } from 'lib/contentful/fetchLayoutContentfulEntries'
import { formatEntry } from 'lib/contentful/formatEntry'

import { Footer } from './Footer/Footer'
import styles from './Global.module.scss'
import { Links } from './Links/Links'
import { StoresByRegion } from './StoresByRegion/StoresByRegion'

type Props = {
  footerEntry: FooterEntry
  isGlobalSupport?: boolean
  layoutContentfulEntries: LayoutContentfulEntries
  storesByRegion: GetGlobalPagesResponse['storesByRegion']
}

export const Global: React.FC<Props> = ({
  isGlobalSupport = true,
  footerEntry,
  layoutContentfulEntries,
  storesByRegion,
}) => (
  <div>
    <NicotineWarning
      contentfulEntry={layoutContentfulEntries.nicotineWarningEntry}
    />
    <ContentWrapper className={styles.contentWrapper}>
      <div className={styles.title}>
        {{
          false: <TranslatedMessage id='global_pages.global_landing.message' />,
          true: <TranslatedMessage id='global_pages.global_support.message' />,
        }[String(isGlobalSupport)]}
      </div>

      <StoresByRegion
        isGlobalSupport={isGlobalSupport}
        storesByRegion={storesByRegion}
      />
      <Links />
    </ContentWrapper>
    {footerEntry && <Footer footerEntry={formatEntry(footerEntry)} />}
  </div>
)
